<template>
  <!--That Success Page it's last page after confirmation all thing-->
  <v-container
    fluid
    grid-list-xs
    fill-height
    justify-center
    align-center
    text-center
    color="bg"
  >
    <v-card width="90%" color="white" elevation-5 class="rounded-xl py-10 px-5">
      <div>
        <v-img src="@/assets/img/tick.png" width="70%" class="mb-8 mx-auto" />
      </div>

      <div>
        <h6 class="grey--text text--lighten-1">
          {{ $t("generic.lang_orderSuccessfull") }}
        </h6>
        <!--        <h2 class="py-4 font-weight-bold">{{new Date().toDateString()}}</h2>-->
        <h6 class="grey--text text--lighten-1">
          {{ $t("generic.lang_youOrderBeingPrepared") }}
        </h6>
      </div>
      <v-card-actions class="d-block">
        <v-btn
          :loading="this.approving"
          :disabled="this.approving"
          v-if="this.can_order_as_group"
          @click="commitOrders"
          elevation="0"
          color="primary"
          max-width="100%"
          block
          class="d-block mx-auto"
        >
          {{ $t("generic.lang_is_last_order") }}
        </v-btn>
        <br />
        <v-btn
          to="/menu"
          outlined
          elevation="0"
          color="primary"
          block
          class="mx-auto"
        >
          {{ $t("generic.lang_back_to_items") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ENDPOINTS from "@/plugins/axios/endpoints";
import { createNamespacedHelpers } from "vuex";

export default {
  name: "Success",
  async mounted() {
    await this.getOrder(this.$route.params.id);
  },
  data() {
    return {
      approving: false
    };
  },
  computed: {
    ...createNamespacedHelpers("Config").mapGetters(["can_order_as_group"]),
    ...createNamespacedHelpers("Table").mapGetters(["table"])
  },
  methods: {
    ...createNamespacedHelpers("Order").mapMutations(["waitApprove"]),
    commitOrders() {
      this.approving = true;
      this.$axios
        .post(ENDPOINTS.SELF_SERVICE.ORDERS.TEMP_ORDERS.APPROVE, {
          tableName: this.table
        })
        .then(() => {
          //if ordering as group is enabled we have to indicate that this order is marked as last order in the table
          this.waitApprove(false);
          this.$router.push("/menu");
        })
        .catch(() => {
          this.$swal({
            title: this.$t("errors.lang_cant_create_order"),
            text: this.$t("errors.lang_try_again"),
            icon: "error"
          });
        })
        .finally(() => (this.approving = false));
    },
    async getOrder(order_id) {
      if (order_id !== undefined && order_id !== "undefined") {
        return await this.$axios
          .get(
            (this.can_order_as_group
              ? ENDPOINTS.SELF_SERVICE.ORDERS.TEMP_ORDERS.MERGED_ORDERS
              : ENDPOINTS.SELF_SERVICE.ORDERS.GET) + order_id
          )
          .then(res => {
            if (res.status === 200 && typeof res.data === "object") {
              if (this.$route.query.type === "unpaid")
                this.$store.commit("Order/setUnpaidItems", res.data.data.items);
              else this.$store.commit("Order/pushOrder", res.data.data);
              this.$store.commit("Order/clearCard");
            }
          })
          .catch(() => {
            this.$swal({
              title: this.$t("errors.lang_cant_create_order"),
              text: this.$t("errors.lang_try_again"),
              icon: "error"
            });
          });
      } else return new Promise(resolve => resolve(true));
    }
  }
};
</script>
